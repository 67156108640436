<template>
<div class="container">
    <div class="header">
        <img
            :src="isDarkMode ? require('../assets/images/base/ic_public_back_dark.png') : require('../assets/images/base/ic_public_back.png')"
            alt="">
        <span>使用日记</span>
    </div>
    <div class="content">
        <div class="plan-btn-box">
            <div>
                <span>记录日常症状</span>
            </div>
            <div :class="['btn-box', {'color-active': btnStatus}]" @click="clickBtn">
                <div :class="['btn-cancel', {'active': !btnStatus}]"></div>
                <div :class="['btn-confirm', {'active': btnStatus}]"></div>
            </div>
        </div>
        <div class="btn-box-info">
            <span>每次使用前，系统都会发出信息弹窗进行症状记录。</span>
        </div>
        <div class="times">
            <div :class="{'active':currTimeType == 'mon'}" @click="currTimeType = 'mon'">
                <span>月</span>
            </div>
            <div :class="{'active':currTimeType == 'year'}" @click="currTimeType = 'year'">
                <span>年</span>
            </div>
        </div>
        <div class="time-box">
            <div @click="prevTime">
                <span class="iconfont icon-xiangzuo1"></span>
            </div>
            <div>
                <span v-text="currTimeType == 'mon' ? monTimes : yearTimes"></span>
            </div>
            <div @click="nextTime">
                <span class="iconfont icon-xiangyou1"></span>
            </div>
        </div>
        <div class="box" v-if="btnStatus">
            <div class="sleep-data-box">
                <div class="title">
                    <span>入睡困难</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[0]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[0]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep1"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>易睡易醒</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[1]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[1]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep2"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>半夜醒来</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[2]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[2]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep3"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>呼吸不畅</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[3]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[3]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep4"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>咳嗽打鼾</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[4]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[4]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep5"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>感觉到冷</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[5]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[5]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep6"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>感觉到热</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[7]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[7]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep7"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>做恶梦</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[7]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[7]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep8"></div>
            </div>

            <div class="sleep-data-box">
                <div class="title">
                    <span>疼痛不适</span>
                </div>
                <div class="desc">
                    <span v-if="currTimeType == 'mon'">一周次数：<span v-text="arr[8]"></span>次</span>
                    <span v-if="currTimeType == 'year'">一月次数：<span v-text="arr[8]"></span>次</span>
                </div>
                <div class="sleep-data" ref="sleep9"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: "useDiaryLog",
    data() {
        return {
            isDarkMode: false,
            data: {},
            btnStatus: false,
            currTimeType: 'mon', // mon year
            monTime: '2021-12',
            monTimes: '2021年12月',
            yearTime: '2021',
            yearTimes: '2021年',
            arr: [],
            arrIndex: [],
        }
    },
    watch: {
        currTimeType() {
            this.getData()
        },
        btnStatus(val) {
            if (val) this.getData()
        }
    },
    methods: {
        clickBtn() {
            this.btnStatus = this.btnStatus ? false : true;
            this.$axios.post('/api/changeDiary', {
                token: this.token,
                state: this.btnStatus
            }).then(() => {
                this.getData();
            })
        },
        prevTime() {
            if (this.currTimeType == 'mon') {
                let time = this.monTime.split('-');
                let year = time[0];
                let mon = parseInt(parseInt(time[1]) - 1);
                if (mon < 1) {
                    mon = '12';
                    year = parseInt(year) - 1;
                }
                if (mon <= 9) {
                    mon = '0' + mon;
                }
                this.monTime = year + '-' + mon;
                this.monTimes = parseFloat(year) + '年' + parseFloat(mon) + '月';
            } else {
                this.yearTime = parseInt(this.yearTime) - 1;
                this.yearTimes = this.yearTime + '年'
            }
            this.getData()
        },
        nextTime() {
            if (this.currTimeType == 'mon') {
                let time = this.monTime.split('-');
                let year = time[0];
                let mon = parseInt(parseInt(time[1]) + 1);
                if (mon > 12) {
                    mon = '1';
                    year = parseInt(year) + 1;
                }
                if (mon <= 9) {
                    mon = '0' + mon;
                }
                this.monTime = year + '-' + mon;
                this.monTimes = parseFloat(year) + '年' + parseFloat(mon) + '月';
            } else {
                this.yearTime = parseInt(this.yearTime) + 1;
                this.yearTimes = this.yearTime + '年'
            }
            this.getData()
        },
        createEcharts(dom, data, index) {
            let that = this;
            let myEchart = echarts.init(dom);
            let xData = this.currTimeType == 'mon' ?
                ['第1周', '第2周', '第3周', '第4周'] :
                ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
            let max = this.currTimeType == 'mon' ? 6 : 30;
            let interval = this.currTimeType == 'mon' ? 2 : 10;
            let option = {
                grid: {
                    left: '10px',
                    top: '10px',
                    right: '10px',
                    bottom: '5px',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgb(152,152,152)'
                        }
                    },
                    nameTextStyle: {
                        color: 'rgb(152,152,152)'
                    }
                },
                yAxis: {
                    type: 'value',
                    position: 'right',
                    min: 0,
                    max: max,
                    interval: interval,
                    axisLine: {
                        lineStyle: {
                            color: 'rgb(152,152,152)'
                        }
                    }
                },
                series: [
                    {
                        data: data,
                        type: 'bar',
                        barWidth: 10,
                        showBackground: false,
                        itemStyle: {
                            color: function (param) {
                                if (param.dataIndex == that.arrIndex[index]) {
                                    return 'rgb(10,89,246)'
                                } else {
                                    return 'rgb(158,189,253)'
                                }
                            },
                            barBorderRadius: [10, 10, 0, 0]
                        }
                    }
                ]
            };
            myEchart.setOption(option, true);
            //点击事件
            myEchart.on('click', function (params) {
                that.arrIndex[index] = params.dataIndex
                that.arr[index] = params.value
                //柱形图重构
                myEchart.setOption(option);
            })
        },
        getData() {
            this.show = 'top';
            this.$axios.post('/api/sleepDiaryData', {
                token: this.token,
                time: this.currTimeType == 'mon' ? this.monTime : this.yearTime,
                type: this.currTimeType
            }).then((response) => {
                let data = response.data;
                this.data = data.data;
                let arr = [];
                let arrIndex = [];
                for (let i in data.data) {
                    arr.push(data.data[i][0])
                    arrIndex.push(0)
                }
                this.arr = arr;
                this.arrIndex = arrIndex;
                this.createEcharts(this.$refs.sleep1, this.data[0], 0)
                this.createEcharts(this.$refs.sleep2, this.data[1], 1)
                this.createEcharts(this.$refs.sleep3, this.data[2], 2)
                this.createEcharts(this.$refs.sleep4, this.data[3], 3)
                this.createEcharts(this.$refs.sleep5, this.data[4], 4)
                this.createEcharts(this.$refs.sleep6, this.data[5], 5)
                this.createEcharts(this.$refs.sleep7, this.data[6], 6)
                this.createEcharts(this.$refs.sleep8, this.data[7], 7)
                this.createEcharts(this.$refs.sleep9, this.data[8], 8)
            })

            // this.$axios.get('/api/healthkit/readSleep?time=' + this.sleepTime + '&type=' + this.timeType, {
            //     headers: {'token': this.token},
            // }).then((response) => {
            //     let data = response.data;
            //     this.data = data.data;
            // })
        },
        getUrlKey: function (name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
                .exec(location.href) || ['', ""])[1].replace(/\+/g, '%20')) || null
        },
        getIsDiary() {
            this.$axios.get('/api/getIsDiary?token=' + this.token).then((response) => {
                let ret = response.data;
                this.btnStatus = (ret.data == '1');
                if (this.btnStatus) this.getData();
            })
        },
    },
    mounted() {
        this.getIsDiary()
    },
    created() {
        this.token = this.getUrlKey('token');
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/useDiaryLog";
</style>
